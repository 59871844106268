/* eslint-disable @typescript-eslint/no-empty-function */
import type { ReactElement, ReactNode, RefObject } from 'react';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import type { StyleVariant } from '../../../../utilities/graphql/codegen';
import { LowestPriceDisplay } from '../../../../utilities/graphql/codegen';
import { useStaticContext } from '../../../../utilities/context/static/StaticContext';
import { parseVariantToProduct } from '../../../../utilities/parsers';
import { VariantArea } from '../VariantArea';
import type { RecentlyViewedProduct } from '../../../../utilities/helpers';
import { parseFormattedPrice } from '../../../../utilities/helpers';
import { S } from './styles';
import { TileImage } from './TileImage';
import { TilePriceStrikethrough } from './TilePriceStrikethrough';
import { TileFiberRanking } from './TileFiberRanking';
import { TileLegacySignings } from './TileLegacySignings';
import type { TileType } from './types';
import { FEATURE_TOGGLE } from '../../../../utilities/constants';
import { TileSignings } from './TileSignings';
import type { ProductPrice } from '../../../../amplienceTypes/schemas/imported/product-price-schema';
import { TilePriceLowest30Days } from './TilePriceLowest30Days';
import { TileTitleWithPrice } from './TileTitleWithPrice';
import { FIBER_RANKING_SCORE_THRESHOLD } from '../../../../utilities/constants/fiberRanking';

interface TileProps {
  signingEnabled: boolean;
  product: RecentlyViewedProduct;
  showVariantSelector: boolean;
  variants: StyleVariant[] | undefined;
  wishlistAction?: ReactNode;
  quickView?: ReactNode;
  onLinkClick?: () => void;
  onVariantLinkClick?: () => void;
  tileType: TileType;
  productPrice?: ProductPrice;
}

export const Tile = ({
  signingEnabled,
  product,
  showVariantSelector,
  variants,
  wishlistAction,
  quickView,
  tileType,
  productPrice,
  onLinkClick = () => {},
  onVariantLinkClick = () => {
    /* silent */
  },
}: TileProps): ReactElement => {
  const [productHovered, setProductHovered] = useState(false);
  const [variantHovered, setVariantHovered] = useState(-1);
  const [singleProduct, setSingleProduct] = useState<RecentlyViewedProduct>(product);
  const wrapperRef: RefObject<HTMLElement> = useRef(null);
  const { t } = useTranslation('common', { keyPrefix: 'productPrice' });
  const {
    quickViewContent,
    configuration: {
      enableDarkBgImages,
      fiberRanking,
      displayFiberRankingOnProductTiles,
      lowestPriceDisplay,
      showLowestPriceText,
    },
  } = useStaticContext();

  const basePriceColorFallback =
    quickViewContent?.productPrice?.content?.basePriceColor || t('basePriceColor');
  const showFiberRanking =
    displayFiberRankingOnProductTiles &&
    fiberRanking &&
    product.fiberRanking?.fiberRanking?.productFiberRankingData?.score &&
    FIBER_RANKING_SCORE_THRESHOLD <=
      product.fiberRanking.fiberRanking.productFiberRankingData.score;

  const hasVariantArea = showVariantSelector && variants?.length !== 0;

  const fromPrice = product.fromPrice?.formattedValue ?? product.formattedFromPrice ?? '';
  const basePrice = product.price?.formattedValue ?? product.formattedBasePrice ?? '';

  const parsedFromPrice = parseFormattedPrice(fromPrice);
  const parsedBasePrice = parseFormattedPrice(basePrice);
  const hasDiscount = parsedBasePrice < parsedFromPrice;

  const DisplayLowestPriceMap: Record<LowestPriceDisplay, boolean | undefined | null> = {
    [LowestPriceDisplay.Always]: true,
    [LowestPriceDisplay.Never]: false,
    [LowestPriceDisplay.DiscountsOnly]: hasDiscount && showLowestPriceText,
  };
  const renderLowestPrice = DisplayLowestPriceMap[lowestPriceDisplay || LowestPriceDisplay.Never];
  const productData = JSON.stringify({
    baseProduct: singleProduct?.baseProduct,
    comingSoon: singleProduct?.comingSoon,
    staticCategoryPathIds: singleProduct?.staticCategoryPathIds,
    stockQuantity: singleProduct?.stockQuantity,
    mcc: singleProduct?.mccValue,
  });

  useEffect(() => {
    if (variantHovered >= 0 && variants?.length) {
      const parsedProduct = parseVariantToProduct(variants[variantHovered], enableDarkBgImages);

      setSingleProduct(parsedProduct as RecentlyViewedProduct);
    } else {
      setSingleProduct(product);
    }
  }, [variantHovered, product, enableDarkBgImages, variants]);

  if (!singleProduct.url) {
    return <></>;
  }

  return (
    <S.Tile data-testid="product-tile" data-cs-capture="">
      <S.TileLink
        ariaLabel={singleProduct.name || ''}
        href={singleProduct.url}
        testId="product-tile-link"
        data-hover-variant-area={hasVariantArea}
        data-slug={singleProduct.baseProduct}
        data-product-data={productData}
        data-product-code={product.code}
        data-cs-capture=""
        onClick={() => sessionStorage?.setItem('plpNavigatedProduct', product.code)}
      >
        <S.ProductWrapper ref={wrapperRef as RefObject<HTMLDivElement>} onClick={onLinkClick}>
          {showFiberRanking && <TileFiberRanking wrapper={S.FiberSigningsWrapper} />}
          <S.WishlistActionWrapper>{wishlistAction}</S.WishlistActionWrapper>

          <S.RelativeElement
            data-testid="product-tile-image"
            data-cs-capture=""
            onMouseEnter={() => setProductHovered(true)}
            onMouseLeave={() => setProductHovered(false)}
          >
            <TileImage
              tileType={tileType}
              singleProduct={singleProduct}
              variantHovered={variantHovered}
              productHovered={productHovered}
            />

            {FEATURE_TOGGLE.NEW_TILE_SIGNINGS ? (
              <S.SigningsArea>
                <TileSignings signingEnabled={signingEnabled} singleProduct={singleProduct} />

                <S.QuickViewWrapper>{quickView}</S.QuickViewWrapper>
              </S.SigningsArea>
            ) : (
              // TODO: proper cleanup + file removal
              <TileLegacySignings
                quickViewAction={<S.QuickViewWrapper>{quickView}</S.QuickViewWrapper>}
                singleProduct={singleProduct}
                signingEnabled={signingEnabled}
                className={showFiberRanking ? 'fiber-ranking-visible' : ''}
              />
            )}
          </S.RelativeElement>

          <S.ProductData>
            <S.RelativeElement>
              <S.InformationArea>
                {lowestPriceDisplay === LowestPriceDisplay.Never ? (
                  <>
                    <S.OneLineText
                      variant="subtitle"
                      component="p"
                      alignment="center"
                      testId="product-tile-title"
                      data-cs-capture=""
                    >
                      {singleProduct.name}
                    </S.OneLineText>

                    <TilePriceStrikethrough
                      singleProduct={singleProduct}
                      discountedColor={basePriceColorFallback}
                    />
                  </>
                ) : (
                  <TileTitleWithPrice
                    renderLowestPrice={renderLowestPrice}
                    product={singleProduct}
                    discountedColor={basePriceColorFallback}
                  />
                )}
              </S.InformationArea>

              {hasVariantArea && (
                <VariantArea
                  wrapperRef={wrapperRef}
                  product={product}
                  variants={variants}
                  onVariantLinkClick={onVariantLinkClick}
                  setVariantHovered={setVariantHovered}
                />
              )}
            </S.RelativeElement>

            {renderLowestPrice && (
              <TilePriceLowest30Days
                fromPrice={fromPrice}
                productPrice={productPrice}
                alwaysDisplayed={lowestPriceDisplay === LowestPriceDisplay.Always}
                product={singleProduct}
              />
            )}
          </S.ProductData>
        </S.ProductWrapper>
      </S.TileLink>
    </S.Tile>
  );
};
