import type { ReactElement, RefObject, SetStateAction } from 'react';
import { useRef } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import type { Product, StyleVariant } from '../../../utilities/graphql/codegen';
import { LowestPriceDisplay } from '../../../utilities/graphql/codegen';
import { colors, media } from '../core/styles';
import { VariantAreaDiv } from './tile/styles';
import { ImageWithLoaderIndicator } from '../common/ImageWithLoaderIndicator';
import { useStaticContext } from '../../../utilities/context/static/StaticContext';
import { hideScrollBar } from '../common/styles';
import { useOverflowIndicators } from '../../../utilities/hooks/useOverflowIndicators';
import { usePointerAutoscroll } from '../../../utilities/hooks/usePointerAutoscroll';

const S = {
  Variants: styled.div`
    overflow-x: scroll;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    ${hideScrollBar}

    @media ${media.greaterThan('sm')} {
      overflow-x: visible;
    }
  `,

  VariantsScrollArea: styled.div.attrs<{
    $align: 'center' | 'left';
  }>(({ $align }) => ({
    style: {
      textAlign: $align,
    },
  }))`
    /* stylelint-disable declaration-property-value-no-unknown, custom-property-no-missing-var-function */

    ${hideScrollBar}

    position: relative;
    padding: 0;
    min-width: 100%;
    white-space: nowrap;
    text-align: center;
    overflow: scroll;

    @media ${media.greaterThan('sm')} {
      will-change: transform;
    }

    --_scrollarea-overflow-start: 0.01px;
    --_scrollarea-overflow-end: 0.01px;

    &[data-has-overflow-start='true'] {
      --_scrollarea-overflow-start: 24px;
    }

    &[data-has-overflow-end='true'] {
      --_scrollarea-overflow-end: 24px;
    }

    mask-image: linear-gradient(
      90deg,
      transparent 0%,
      black var(--_scrollarea-overflow-start),
      black calc(100% - var(--_scrollarea-overflow-end)),
      transparent 100%
    );

    @property --_scrollarea-overflow-start {
      syntax: '<length-percentage>';
      inherits: true;
      initial-value: 0.01px;
    }

    @property --_scrollarea-overflow-end {
      syntax: '<length-percentage>';
      inherits: true;
      initial-value: 0.01px;
    }

    @media (prefers-reduced-motion: no-preference), (prefers-reduced-motion: reduce) {
      transition: --_scrollarea-overflow-start 0.1s linear, --_scrollarea-overflow-end 0.1s linear;
      will-change: mask-image;
    }
  `,

  Variant: styled.button`
    padding: 0;
    border: 0;
    box-shadow: 0 0;
    cursor: pointer;
    position: relative;
    display: inline-block;
    margin-bottom: 4px;

    &:not(:first-child) {
      padding-left: 12px;
    }

    &[data-state='on'] > * {
      box-sizing: border-box;
      overflow: hidden;
      width: 24px;
      height: 24px;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -4px;
      max-width: 24px;
      width: 100%;
      height: 2px;
      background-color: ${colors.BLUE_CHARCOAL};
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.2s ease;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }

    &[data-state='on']::after {
      transform: scaleX(1);
    }
  `,

  ColorVariant: styled.div`
    width: 24px;
    height: 24px;
    overflow: hidden;
    border-radius: 50%;

    * {
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 50%;
      box-sizing: border-box;
    }
  `,

  Color: styled.div<{ $color: string }>`
    background: ${({ $color }) => $color};
  `,
};

interface VariantAreaProps {
  product: Product;
  setVariantHovered: (variantHovered: SetStateAction<number>) => void;
  variants: StyleVariant[] | undefined;
  wrapperRef: RefObject<HTMLElement>;
  className?: string;
  onVariantLinkClick?: () => void;
}

const srcSizes = [24, 48, 72];

export const VariantArea = ({
  product,
  setVariantHovered,
  variants,
  wrapperRef,
  className,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onVariantLinkClick = () => {},
}: VariantAreaProps): ReactElement => {
  const {
    configuration: { lowestPriceDisplay },
  } = useStaticContext();
  const variantsScrollAreaRef = useRef<HTMLDivElement>(null);
  const isLowestPriceHidden: boolean = lowestPriceDisplay === LowestPriceDisplay.Never;

  useOverflowIndicators({ targetElementRef: variantsScrollAreaRef });
  usePointerAutoscroll({ parentElementRef: wrapperRef, targetElementRef: variantsScrollAreaRef });

  const makeImageUrl = (imageUrl: string, imageParams = 'w_24,ar_1') =>
    imageUrl.replace('{{dimensions}}', imageParams).replace('q_80', 'q_auto');

  const filteredVariants = variants?.filter(
    ({ facetColorStyle, images }) => !!facetColorStyle || !!images?.swatch
  );

  return filteredVariants?.length !== 0 ? (
    <VariantAreaDiv className={className}>
      <S.Variants>
        <S.VariantsScrollArea
          ref={variantsScrollAreaRef}
          $align={isLowestPriceHidden ? 'center' : 'left'}
        >
          {filteredVariants?.map(({ productUrl, name, images, facetColorStyle }, i) => {
            let srcSet = '';

            if (images?.swatch) {
              srcSet = srcSizes
                .map(size => `${makeImageUrl(images.swatch as string, `w_${size},ar_1`)} ${size}w`)
                .join(', ');
            }

            return (
              productUrl && (
                <S.Variant
                  key={i}
                  data-testid="product-tile-style-variant"
                  aria-label={`variant-${name}`}
                  data-state={
                    productUrl?.toLowerCase() === product.url?.toLowerCase() ? 'on' : 'off'
                  }
                  onMouseEnter={() => setVariantHovered(i)}
                  onFocus={() => setVariantHovered(i)}
                  onMouseLeave={() => setVariantHovered(-1)}
                  onBlur={() => setVariantHovered(-1)}
                  onClick={onVariantLinkClick}
                >
                  <Link href={productUrl} prefetch={false} legacyBehavior>
                    <S.ColorVariant>
                      {images?.swatch ? (
                        <ImageWithLoaderIndicator
                          src={makeImageUrl(images.swatch)}
                          alt={name ?? ''}
                          shimmerDuration="1s"
                          sizes="(-webkit-min-device-pixel-ratio: 2) 48px, (-webkit-min-device-pixel-ratio: 3) 72px, 24px"
                          srcSet={srcSet}
                        />
                      ) : (
                        <S.Color $color={facetColorStyle as string} />
                      )}
                    </S.ColorVariant>
                  </Link>
                </S.Variant>
              )
            );
          })}
        </S.VariantsScrollArea>
      </S.Variants>
    </VariantAreaDiv>
  ) : (
    <></>
  );
};
