import { S } from './TileTitleWithPrice';

type TileTitleOriginalPriceProps = {
  originalPriceCopy: string | undefined;
  discountedPrice: string | undefined;
  initialPrice: string | undefined;
  discountedColor?: string;
};

export const TileTitleOriginalPrice = ({
  originalPriceCopy,
  discountedPrice,
  initialPrice,
  discountedColor,
}: TileTitleOriginalPriceProps) => (
  <div data-testid="product-tile-original-price">
    {initialPrice && discountedPrice !== initialPrice ? (
      <S.PriceLowest30Days>
        {originalPriceCopy} {initialPrice.replace(' ', '')}
      </S.PriceLowest30Days>
    ) : (
      <></>
    )}
    <span>
      {discountedPrice && discountedPrice !== initialPrice ? (
        <S.DiscountedPrice $color={discountedColor} data-testid="product-tile-price">
          {discountedPrice.replace(' ', '')}
        </S.DiscountedPrice>
      ) : (
        <S.InitialPrice data-testid="product-tile-price">{initialPrice}</S.InitialPrice>
      )}
    </span>
  </div>
);
