import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import type { RecentlyViewedProduct } from '../../../../utilities/helpers';
import { colors } from '../../core/styles';
import type { ProductPrice } from '../../../../amplienceTypes/schemas/imported/product-detail-info-schema';
import { TileTitleOriginalPrice } from './TileTitleOriginalPrice';
import { TileTitlePrice } from './TileTitlePrice';
import { baseTextStyle } from './styles';

export const S = {
  Wrapper: styled.div`
    ${baseTextStyle};
    color: ${colors.BLUE_CHARCOAL};
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,

  PriceWrapper: styled.div`
    display: flex;
  `,

  PriceLowest30Days: styled.span`
    ${baseTextStyle};
    font-weight: bold;
    color: ${colors.RAVEN_GREY};
    text-decoration: line-through;
  `,

  Title: styled.p`
    ${baseTextStyle};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,

  DiscountedPrice: styled.span<{ $color?: string | null }>`
    ${baseTextStyle};
    white-space: nowrap;
    font-weight: bold;
    color: ${({ $color }) => $color || colors.LIGHT_GREEN};
    margin-left: 4px;
  `,

  InitialPrice: styled.span`
    white-space: nowrap;
    font-weight: bold;
    color: ${colors.BLUE_CHARCOAL};
  `,
};

interface TileTitleWithPriceProps {
  product: RecentlyViewedProduct;
  className?: string;
  productPrice?: ProductPrice;
  renderLowestPrice?: boolean | null;
  discountedColor?: string;
}

export const TileTitleWithPrice = ({
  product,
  className,
  productPrice,
  renderLowestPrice,
  discountedColor,
}: TileTitleWithPriceProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'productPrice' });

  const discountedPrice = product.price?.formattedValue || product.formattedBasePrice;
  const initialPrice = product.fromPrice?.formattedValue || product.formattedFromPrice;
  const originalPriceCopy = (productPrice?.originalPrice as string) || t('originalPrice');

  return (
    <S.Wrapper>
      {renderLowestPrice ? (
        originalPriceCopy && (
          <TileTitleOriginalPrice
            originalPriceCopy={originalPriceCopy}
            discountedPrice={discountedPrice}
            initialPrice={initialPrice}
            discountedColor={discountedColor}
          />
        )
      ) : (
        <TileTitlePrice
          discountedPrice={discountedPrice}
          initialPrice={initialPrice}
          originalPriceCopy={originalPriceCopy}
          discountedColor={discountedColor}
        />
      )}
      <S.Title className={className} data-testid="product-tile-title">
        {product.name}
      </S.Title>
    </S.Wrapper>
  );
};
